import {createSelector} from 'reselect';

import _forEach from 'lodash-es/forEach';
import _isEmpty from 'lodash-es/isEmpty';
import _times from 'lodash-es/times';

import * as Enums from 'Clients/Catalog/Enums';

import RootState from 'Store/Root';
import {enginePalettesMapSelector} from 'Store/YarnLibraries/Selectors';
import {mapYarnToOverview} from 'Store/YarnLibraries/Utils';

import {QueryArgs} from 'Types/Rugs';
import {YarnOverview} from 'Types/Yarns';

import {engineIdSelector} from './EngineId';
import {loadingSelector} from './Loading';
import {overviewsSelector} from './Overviews';

export const yarnPaletteSelector = createSelector(
  loadingSelector,
  engineIdSelector,
  enginePalettesMapSelector,
  (state: RootState, query: QueryArgs) => state.yarnLibraries.items,
  (state: RootState, query: QueryArgs) => query,
  (status, engineId, paletteMap, yarnLibs, query): YarnOverview[] => {
    if (!status.loaded || engineId <= 0 || yarnLibs.length === 0) {
      return [];
    }

    const enginePal = paletteMap[engineId.toString()];

    const palette = _isEmpty(query.palette) ? enginePal : query.palette;

    let paletteYarns = palette.map(x => findYarn(x, yarnLibs));

    const maxSize = getMaxPaletteSize(engineId);
    const fillVacancies = query.positions.some(x => x.length === 0) && paletteYarns.length < maxSize;

    if (fillVacancies) {
      console.log('TODO: Do something about vacancies in palette?');
    }

    return paletteYarns;
  }
);

export const yarnPositionsSelector = createSelector(
  loadingSelector,
  overviewsSelector,
  yarnPaletteSelector,
  (state: RootState, query: QueryArgs) => query,
  (status, overviews, paletteYarns, query): YarnOverview[][] => {
    if (!status.loaded || overviews.length === 0 || paletteYarns.length === 0) {
      return [];
    }

    return query.designs.map((d, idx) => {
      if (_isEmpty(query.positions[idx])) {
        return _times(overviews[idx].threadCount, x => paletteYarns[x % paletteYarns.length]);
      } else {
        return query.positions[idx].map(x => paletteYarns[x]);
      }
    });
  }
);

function findYarn(code: string, libs: TrykApi.Yarns.IYarnLibrary[]): YarnOverview {
  const findInLib = (item: string, lib: TrykApi.Yarns.IYarnLibrary): TrykApi.Yarns.IYarn => {
    return lib.yarns.find(x => x.code.toUpperCase() === (item || '').toUpperCase()) || null;
  };

  const findInLibs = (item: string): TrykApi.Yarns.IYarn => {
    let output: TrykApi.Yarns.IYarn = null;

    _forEach(libs, x => {
      output = findInLib(item, x);
      // Exit iteration by returning false if we have a result.
      return output === null;
    });

    return output;
  };

  let result: TrykApi.Yarns.IYarn = null;

  if (code.startsWith('[') && code.endsWith(']')) {
    const children = code.substr(1, code.length - 2).split(',').map(x => findInLibs(x));
    const displayCode = code.substr(1, code.length - 2).replace(',', '/');

    result = {
      yarnId: 1,
      yarnTypeId: 1,
      yarnType: 'Twist',
      code: displayCode,
      name: displayCode,
      masterLibraryCode: null,
      masterLibraryId: -1,
      constructions: children.map((x): TrykApi.Yarns.IYarnConstruction => ({
        yarnConstructionId: 1,
        parentYarnId: 1,
        child: x
      })),
      properties: {
        isMetallic: false
      },
    };
  } else {
    result = findInLibs(code);
  }

  if (result) {
    return mapYarnToOverview(result);
  } else {
    return null;
  }
}

function getMaxPaletteSize(engineId: number): number {
  if (engineId === Enums.Engines.Id.Cyp) {
    return 6;
  }

  return 10;
}
