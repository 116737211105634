import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import RootState from 'Store/Root';
import { incrementFolderItemCount } from 'Store/Projects/Actions';

import { Params } from 'Types/Designs';

import { DesignAction } from './Action';

export const SAVING_DESIGN = 'SAVING_DESIGN';
export const SAVED_DESIGN = 'SAVED_DESIGN';
export const SAVE_DESIGN_FAILED = 'SAVE_DESIGN_FAILED';

export function saveDesign(folderId: number, params: Params): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    dispatch(savingDesign());

    return CatalogClient.Favorites.saveColorway({
      folderId,
      colorway: params.colorway,
      installId: params.viewMode === '3d' && params.install && params.install.installId || 0,
      roomId: params.viewMode === '3d' && params.room && params.room.roomId || 0,
      textureId: params.texture && params.texture.textureId || 0
    }).then(
      x => {
        dispatch(incrementFolderItemCount(folderId));
        dispatch(designSaved());
      },
      err => dispatch(saveDesignFailed())
      );
  };
}

function savingDesign(): DesignAction {
  return {
    type: SAVING_DESIGN
  };
}

function saveDesignFailed(): DesignAction {
  return {
    type: SAVE_DESIGN_FAILED
  };
}

function designSaved(): DesignAction {
  return {
    type: SAVED_DESIGN
  };
}
