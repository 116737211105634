import { Favorites as FavoritesClient } from "Clients/Catalog";
import * as ImagesClient from "Clients/Images";
import { Urls as StudioUrls } from "Clients/Studio";

import { isSavedColorway, isSavedRug, SavedItem } from "Types/Projects";

import { QueryArgs as DesignQuery } from "Types/Designs";
import { QueryArgs as RugQuery } from "Types/Rugs";

import { fromNow, prettyDate } from "Utils/PrettyDate";
import { serializeYarnCodes } from "Utils/Yarns";

const PREVIEW_PIXEL_SIZE = 1024;

export function mapColorwayToQuery(
  colorway: TrykApi.Catalog.Favorites.IColorway
): DesignQuery {
  const args: DesignQuery = {
    designCode: colorway.designCode.toUpperCase()
  };

  if (colorway.colorwayId > 0 && colorway.textureId <= 0) {
    args.colorCode = colorway.colorCode.toUpperCase();
  } else {
    args.textureId = colorway.textureId;
    args.yarnCodes = colorway.yarns.map(x =>
      x.yarnId > 10
        ? x.code.toUpperCase()
        : x.children.map(y => y.code.toUpperCase())
    );
  }

  args.installId = colorway.installId;
  args.roomId = colorway.roomId;
  args.viewMode = colorway.roomId > 0 ? "3d" : "2d";
  args.clutColorwayId = (colorway && colorway.clutColorwayId) || "";

  return args;
}

export function mapRugToQuery(rug: TrykApi.Catalog.Favorites.IRug): RugQuery {
  const args: RugQuery = {
    designs: rug.components.map(x => x.designCode.toUpperCase()),
    sections: rug.components.map(x => x.sectionId),
    scales: rug.components.map(x => x.scale),
    palette: rug.palette.map(x => x.code),
    positions: rug.components.map(x => x.positions),
    visibles: rug.components.map(x => true),
    textureId: rug.textureId,
    roomId: rug.roomId,
    viewMode: rug.roomId > 0 ? "3d" : "2d"
  };

  return args;
}

export function mapItem(
  item: TrykApi.Catalog.Favorites.ISavedItem,
  flags: { isOwner: boolean; isDeleted: boolean }
): SavedItem {
  if (isSavedColorway(item)) {
    return mapColorwayToItem(item, flags);
  } else if (isSavedRug(item)) {
    return mapRugToItem(item, flags);
  } else {
    throw "Unsupported item type.";
  }
}

function mapColorwayToItem(
  item: TrykApi.Catalog.Favorites.IColorway,
  flags: { isOwner: boolean; isDeleted: boolean }
): SavedItem {
  const {
    createdBy,
    dateCreated,
    dateModified,
    folderId,
    folderItemId,
    projectId,
    installId,
    roomId,
    textureId,
    totalComments,
    ...baseColor
  } = item;

  // TODO: figure out a better work around for this hack.
  const colorway: TrykApi.Catalog.IColorway = {
    ...baseColor,
    brandId: 0,
    engineId: 0,
    isDefault: false,
    recolorable: true,
    enableLockedRecoloring: false,
    rgbs: [],
    hasTonalMap: false,
    yarns: baseColor.yarns.map(y => y as TrykApi.Catalog.IColorwayYarn),
    yarnUsages: []
  };

  return {
    ...flags,
    item,
    id: folderItemId,
    owner: createdBy,
    totalComments: totalComments,
    hrefs: {
      pattern: (() => {
        return StudioUrls.colorway(colorway, {
          textureId: textureId,
          installId: installId,
          roomId: roomId,
          viewMode: (roomId > 0 ? "3d" : "2d") as ("2d" | "3d"),
          clutColorwayId: colorway.clutColorwayId
        });
      })(),
      item: StudioUrls.projectItem(projectId, folderItemId)
    },
    imageUrls: {
      thumbnail: FavoritesClient.getItemThumbnailUrl(folderItemId),
      preview: (() => {
        if (roomId > 0) {
          if (colorway.clutColorwayId && colorway.clutColorwayId !== '') {
            return ImagesClient.Urls.Room.recolor({
              designCode: colorway.designCode,
              yarns: serializeYarnCodes(colorway.yarns),
              roomId: roomId,
              installId: installId,
              texture: textureId,
              pixels: PREVIEW_PIXEL_SIZE,
              clutColorwayId: colorway.clutColorwayId
            });
          } else if (colorway.colorwayId > 0 || colorway.yarns.length === 0) {
            return ImagesClient.Urls.Room.colorway({
              designCode: colorway.designCode,
              colorCode: colorway.colorCode,
              roomId: roomId,
              installId: installId,
              pixels: PREVIEW_PIXEL_SIZE
            });
          } else {
            return ImagesClient.Urls.Room.recolor({
              designCode: colorway.designCode,
              yarns: serializeYarnCodes(colorway.yarns),
              roomId: roomId,
              installId: installId,
              texture: textureId,
              pixels: PREVIEW_PIXEL_SIZE,
              clutColorwayId: colorway.clutColorwayId
            });
          }
        } else {
          if (colorway.clutColorwayId && colorway.clutColorwayId !== '') {
            return ImagesClient.Urls.Repeat.recolor({
              designCode: colorway.designCode,
              yarns: serializeYarnCodes(colorway.yarns),
              square: true,
              texture: textureId,
              pixels: PREVIEW_PIXEL_SIZE,
              clutColorwayId: colorway.clutColorwayId
            });
          } else if (colorway.colorwayId > 0 || colorway.yarns.length === 0) {
            return ImagesClient.Urls.Repeat.colorway({
              designCode: colorway.designCode,
              colorCode: colorway.colorCode,
              square: true,
              pixels: PREVIEW_PIXEL_SIZE
            });
          } else {
            return ImagesClient.Urls.Repeat.recolor({
              designCode: colorway.designCode,
              yarns: serializeYarnCodes(colorway.yarns),
              square: true,
              texture: textureId,
              pixels: PREVIEW_PIXEL_SIZE,
              clutColorwayId: colorway.clutColorwayId
            });
          }
        }
      })()
    },
    display: {
      primary: colorway.designCode,
      secondary:
      colorway.designName && colorway.designName !== colorway.designCode
        ? colorway.designName
        : "",
      created: prettyDate(dateCreated),
      modified: prettyDate(dateModified),
      fromNow: fromNow(dateCreated)
    }
  };
}

function mapRugToItem(
  rug: TrykApi.Catalog.Favorites.IRug,
  flags: { isOwner: boolean; isDeleted: boolean }
): SavedItem {
  if (rug.components.length === 1) {
    return rugComponentToColorwayItem(rug.components[0], rug, flags);
  }

  return {
    ...flags,
    id: rug.folderItemId,
    item: rug,
    owner: rug.createdBy,
    totalComments: rug.totalComments,
    hrefs: {
      pattern: StudioUrls.rug({
        palette: rug.palette.map(x => x.code),
        designs: rug.components.map(x => x.designCode),
        positions: rug.components.map(x => x.positions),
        sections: rug.components.map(x => x.sectionId),
        scales: rug.components.map(x => x.scale),
        visibles: rug.components.map(x => true),
        textureId: rug.textureId,
        roomId: rug.roomId,
        viewMode: rug.roomId > 0 ? "3d" : "2d"
      }),
      item: StudioUrls.projectItem(rug.projectId, rug.folderItemId)
    },
    imageUrls: {
      thumbnail: FavoritesClient.getItemThumbnailUrl(rug.folderItemId),
      preview: (() => {
        if (rug.roomId > 0) {
          return ImagesClient.Urls.Room.rug({
            roomId: rug.roomId,
            designs: rug.components.map(x => x.designCode),
            sections: rug.components.map(x => x.sectionId),
            pal: rug.palette.map(x => x.code),
            pos: rug.components.map(x => x.positions),
            scales: rug.components.map(x => x.scale),
            texture: rug.textureId,
            pixels: PREVIEW_PIXEL_SIZE
          });
        } else {
          return ImagesClient.Urls.Thumbnail.rug({
            designs: rug.components.map(x => x.designCode),
            sections: rug.components.map(x => x.sectionId),
            pal: rug.palette.map(x => x.code),
            pos: rug.components.map(x => x.positions),
            scales: rug.components.map(x => x.scale),
            texture: rug.textureId,
            pixels: PREVIEW_PIXEL_SIZE
          });
        }
      })()
    },
    display: {
      primary: "Custom Rug",
      created: prettyDate(rug.dateCreated),
      fromNow: fromNow(rug.dateCreated),
      modified: prettyDate(rug.dateModified)
    }
  };
}

function rugComponentToColorwayItem(
  comp: TrykApi.Catalog.Favorites.IRugComponent,
  rug: TrykApi.Catalog.Favorites.IRug,
  flags: { isOwner: boolean; isDeleted: boolean }
): SavedItem {
  const colorway: TrykApi.Catalog.Favorites.IColorway = {
    folderItemId: rug.folderItemId,
    folderId: rug.folderId,
    projectId: rug.projectId,
    designId: comp.designId,
    designCode: comp.designCode,
    designName: comp.designName,
    colorwayId: -1,
    colorCode: "Custom",
    colorName: "Custom",
    yarns: comp.positions.map(x => rug.palette[x]),
    textureId: rug.textureId,
    roomId: rug.roomId,
    installId: 1,
    totalComments: rug.totalComments,
    createdBy: rug.createdBy,
    dateCreated: rug.dateCreated,
    dateModified: rug.dateModified,
    clutColorwayId: ""
  };

  return mapColorwayToItem(colorway, flags);
}
