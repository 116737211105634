import _keyBy from 'lodash-es/keyBy';

import { ThunkAction } from 'redux-thunk';

import Config from 'Config';

import * as CatalogClient from 'Clients/Catalog';

import RootState from 'Store/Root';

export const LOADING_EXCLUSIVE_DESIGNS = 'LOADING_EXCLUSIVE_DESIGNS';
export const LOADED_EXCLUSIVE_DESIGNS = 'LOADED_EXCLUSIVE_DESIGNS';
export const LOAD_EXCLUSIVE_DESIGNS_FAILED = 'LOAD_EXCLUSIVE_DESIGNS_FAILED';

export interface ExclusiveDesignsAction {
  type: string;
  items?: { [id: number]: TrykApi.Catalog.ISearchResult };
}

function loading(): ExclusiveDesignsAction {
  return {
    type: LOADING_EXCLUSIVE_DESIGNS
  };
}

function loadFailed(): ExclusiveDesignsAction {
  return {
    type: LOAD_EXCLUSIVE_DESIGNS_FAILED
  };
}

function loaded(items: TrykApi.Catalog.ISearchResult[]): ExclusiveDesignsAction {
  return {
    type: LOADED_EXCLUSIVE_DESIGNS,
    items: _keyBy(items, x => x.designId)
  };
}

export function loadExclusiveDesigns(): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    if (state.exclusiveDesigns.loaded) {
      return Promise.resolve(true);
    }

    dispatch(loading());

    if (Config.studio.exclusiveCategoryId > 0) {
      return CatalogClient.Product.getSearchPage({
        categoryIds: [Config.studio.exclusiveCategoryId],
        page: 0,
        pageSize: 1000
      }).then(
        x => dispatch(loaded(x.items)),
        e => dispatch(loadFailed())
        );
    } else {
      dispatch(loaded([]));
      return Promise.resolve([]);
    }
  };
}
