import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';

import adminReducer, {AdminState} from './Admin/Reducer';
import categoryTreeReducer, {CategoryTreeState} from './CategoryTree/Reducer';
import colorwaysReducer, {ColorwayMap} from './Colorways/Reducer';
import designsReducer, {DesignsStore} from './Designs/Reducer';
import exclusiveDesignsReducer, {ExclusiveDesignsState} from './ExclusiveDesigns/Reducer';
import installTemplatesReducer, {InstallTemplatesState} from './InstallTemplates/Reducer';
import newDesignsReducer, {NewDesignsState} from './NewDesigns/Reducer';
import projectItemCommentsReducer, {ProjectItemCommentsState} from './ProjectItemComments/Reducer';
import projectItemsReducer, {ProjectItemsState} from './ProjectItems/Reducer';
import projectsReducer, {ProjectsState} from './Projects/Reducer';
import roomsReducer, {RoomsState} from './Rooms/Reducer';
import siteReducer, {SiteState} from './Site/Reducer';
import texturesReducer, {TexturesState} from './Textures/Reducer';
import yarnLibrariesReducer, {YarnLibrariesState} from './YarnLibraries/Reducer';

import {
  CollectionsState,
  collectionsReducer
} from './Collections/Reducer';

import {
  PreloadState,
  preloadReducer
} from './Preload/Reducer';

import {
  PrintScalesState,
  printScalesReducer
} from './PrintScales/Reducer';

import {
  RugState,
  rugReducer
} from './Rugs/Reducer';

import {
  TrykLayoutsState,
  trykLayoutsReducer
} from './TrykLayouts/Reducer';

import basketReducer from './Basket/Reducer';
import BasketState from './Basket/State';

import paginationReducer from './Pagination/Reducer';
import PaginationState from './Pagination/State';

import userReducer from './User/Reducer';
import UserState from './User/State';
import SearchResultState from './Search/State';
import searchResultReducer from './Search/Reducer';

interface RootState {
  admin: AdminState;
  basket: BasketState;
  categoryTree: CategoryTreeState;
  collections: CollectionsState;
  colorways: ColorwayMap;
  designs: DesignsStore;
  exclusiveDesigns: ExclusiveDesignsState;
  installTemplates: InstallTemplatesState;
  newDesigns: NewDesignsState;
  projectItemComments: ProjectItemCommentsState;
  projectItems: ProjectItemsState;
  projects: ProjectsState;
  preload: PreloadState;
  rooms: RoomsState;
  rug: RugState;
  textures: TexturesState;
  user: UserState;
  printScales: PrintScalesState;
  searchResults: SearchResultState;
  site: SiteState;
  trykLayouts: TrykLayoutsState;
  yarnLibraries: YarnLibrariesState;
  pagination: PaginationState;
  routing: {
    locationBeforeTransitions: Location
  };
}

export default RootState;

export const rootReducer = combineReducers({
  admin: adminReducer,
  basket: basketReducer,
  categoryTree: categoryTreeReducer,
  collections: collectionsReducer,
  colorways: colorwaysReducer,
  designs: designsReducer,
  exclusiveDesigns: exclusiveDesignsReducer,
  installTemplates: installTemplatesReducer,
  newDesigns: newDesignsReducer,
  preload: preloadReducer,
  projectItemComments: projectItemCommentsReducer,
  projectItems: projectItemsReducer,
  projects: projectsReducer,
  rooms: roomsReducer,
  rug: rugReducer,
  textures: texturesReducer,
  user: userReducer,
  printScales: printScalesReducer,
  site: siteReducer,
  searchResults: searchResultReducer,
  trykLayouts: trykLayoutsReducer,
  yarnLibraries: yarnLibrariesReducer,
  pagination: paginationReducer,
  // Reducers needed by Redux plugins:
  form: formReducer
});
