import URI from 'urijs';

import _isFinite from 'lodash-es/isFinite';

import {
  CustomRugInfo,
  SurfacePosition
} from 'Types/Designs';
import {
  QueryArgs as RugQuery,
  RugViewMode
} from 'Types/Rugs';
import { YarnCodeList } from 'Types/Yarns';
import { serializeRgbs } from 'Utils/Rgb';
import { serializeYarnCodes } from 'Utils/Yarns';

export interface ISearchOptions {
  categoryIds?: number[];
  pageSize?: number;
  page?: number;
  query?: string;
}

export function search(opts?: ISearchOptions) {
  const uri = new URI('/search');

  if (opts && Array.isArray(opts.categoryIds) && opts.categoryIds.length > 0) {
    uri.addQuery({
      'c': opts.categoryIds.join(',')
    });
  }

  if (opts && Number.isInteger(opts.page) && opts.page > 0) {
    uri.addQuery({
      'p': opts.page
    });
  }

  if (opts && Number.isInteger(opts.pageSize) && opts.pageSize > 0) {
    uri.addQuery({
      's': opts.pageSize
    });
  }

  if (opts && opts.query && opts.query.length > 0) {
    uri.addQuery({
      'q': opts.query
    });
  }

  return uri.toString();
}

export type IColorwayParams = {
  textureId?: number;
  roomId?: number;
  installId?: number;
  viewMode?: '2d' | '3d';
  clutColorwayId?: string;
  mutedIndices?: number[];
  customRug?: CustomRugInfo;
  rotation?: number;
  position?: SurfacePosition;
};

export function colorway(colorway: TrykApi.Catalog.IColorway, { ...params }: IColorwayParams): string {
  if (params.clutColorwayId || (params.mutedIndices && params.mutedIndices.length > 0)) {
    return recolorDesign({
      refColorCode: colorway.colorCode,
      designCode: colorway.designCode,
      yarns: colorway.yarns,
      ...params
    });
  }
  if (colorway.colorwayId > 0) {
    return designDetail({
      designCode: colorway.designCode,
      colorCode: colorway.colorCode,
      ...params
    });
  } else {
    return recolorDesign({
      refColorCode: colorway.colorCode,
      designCode: colorway.designCode,
      yarns: colorway.yarns,
      rgbs: colorway.rgbs,
      ...params
    });
  }
}

export type IDesignDetailParams = {
  designCode: string;
  colorCode?: string;
  roomId?: number;
  installId?: number;
  textureId?: number;
  viewMode?: '2d' | '3d';
  clutColorwayId?: string;
  mutedIndices?: number[];
  customRug?: CustomRugInfo;
  rotation?: number;
  position?: SurfacePosition;
};

export function designDetail(params: IDesignDetailParams) {
  const uri = new URI('/designs/' + params.designCode);
  if (params.colorCode) {
    uri.segment('colors').segment(params.colorCode);
  }

  if (params.roomId) {
    uri.addQuery({
      'r': params.roomId
    });
  }

  if (params.installId) {
    uri.addQuery({
      'i': params.installId
    });
  }

  if (params.textureId) {
    uri.addQuery({
      't': params.textureId
    });
  }

  if (params.viewMode) {
    uri.addQuery({
      'v': params.viewMode
    });
  }

  if (params.customRug) {
    uri.addQuery({
      'rugwidth': params.customRug.rugWidth,
      'rugheight': params.customRug.rugHeight,
      'rugshape': params.customRug.rugShape || 'rect'
    })
  }

  if (params.rotation && params.rotation > 0) {
    uri.addQuery({
      'rot': params.rotation
    });
  }

  if (_isFinite(params.position?.textureOrigin?.x) && _isFinite(params.position?.textureOrigin?.y)) {
    uri.addQuery({
      'to_x': params.position.textureOrigin.x.toString().substring(0, 7),
      'to_y': params.position.textureOrigin.y.toString().substring(0, 7)
    });
  } else if (_isFinite(params.position?.translate?.x) && _isFinite(params.position?.translate?.y)) {
    uri.addQuery({
      't_x': params.position.translate.x.toString().substring(0, 7),
      't_y': params.position.translate.y.toString().substring(0, 7)
    });
  }

  return uri.toString();
}

type Rgb = TrykApi.Catalog.IRgb;

export type IRecolorDesignParams = {
  designCode: string;
  rgbs?: Rgb[];
  yarns?: YarnCodeList;
  mutedIndices?: number[];
  clutColorwayId?: string;
  refLibs?: string;
  refColorCode?: string;
  roomId?: number;
  installId?: number;
  textureId?: number;
  viewMode?: '2d' | '3d';
  customRug?: CustomRugInfo;
  rotation?: number;
  position?: SurfacePosition;
};

export function recolorDesign(params: IRecolorDesignParams) {

  const uri = new URI('/designs/' + params.designCode + '/custom');

  const yarns = params.yarns as TrykApi.Yarns.IYarn[];
  let libIds = params.refLibs;
  if (typeof (libIds) === 'undefined' && typeof (yarns) !== 'undefined' && yarns !== null) {
    libIds = yarns.map(x => x.masterLibraryCode).join(',');
  }
  if (params.yarns && params.yarns.length > 0) {
    uri.addQuery({
      'y': serializeYarnCodes(params.yarns)
    });
  }

  if (params.mutedIndices && params.mutedIndices.length > 0) {
    uri.addQuery({
      'm': params.mutedIndices.join(",")
    });
  }

  if (params.rgbs && params.rgbs.length > 0) {
    uri.addQuery({
      'rgb': serializeRgbs(params.rgbs)
    });
  }

  if (params.clutColorwayId) {
    uri.addQuery({
      'clut': params.clutColorwayId
    });
  }

  if (libIds) {
    uri.addQuery({
      'refLibs': libIds
    });
  }

  if (params.refColorCode) {
    uri.addQuery({
      'ref': params.refColorCode
    });
  }

  if (params.roomId) {
    uri.addQuery({
      'r': params.roomId
    });
  }

  if (params.installId) {
    uri.addQuery({
      'i': params.installId
    });
  }

  if (params.textureId) {
    uri.addQuery({
      't': params.textureId
    });
  }

  if (params.viewMode) {
    uri.addQuery({
      'v': params.viewMode
    });
  }

  if (params.customRug) {
    uri.addQuery({
      'rugwidth': params.customRug.rugWidth,
      'rugheight': params.customRug.rugHeight,
      'rugshape': params.customRug.rugShape || 'rect'
    })
  }

  if (params.rotation && params.rotation > 0) {
    uri.addQuery({
      'rot': params.rotation
    });
  }

  if (_isFinite(params.position?.textureOrigin?.x) && _isFinite(params.position?.textureOrigin?.y)) {
    uri.addQuery({
      'to_x': params.position.textureOrigin.x.toString().substring(0, 7),
      'to_y': params.position.textureOrigin.y.toString().substring(0, 7)
    });
  } else if (_isFinite(params.position?.translate?.x) && _isFinite(params.position?.translate?.y)) {
    uri.addQuery({
      't_x': params.position.translate.x.toString().substring(0, 7),
      't_y': params.position.translate.y.toString().substring(0, 7)
    });
  }

  return uri.toString();
}

type RugParams = TrykApi.Catalog.IRug & {
  textureId: number;
  roomId: number;
  viewMode: RugViewMode;
};

function isRugParams(arg: any): arg is RugParams {
  return Array.isArray(arg.components);
}

export function rug(rug: RugParams | RugQuery): string {
  if (isRugParams(rug)) {
    return rugFromParams(rug);
  } else {
    return rugFromQuery(rug as RugQuery);
  }
}

function rugFromParams(rug: RugParams): string {
  const query: RugQuery = {
    designs: rug.components.map(x => x.designCode.toUpperCase()),
    sections: rug.components.map(x => x.sectionId),
    scales: rug.components.map(x => x.scale),
    palette: rug.palette.map(x => x.code),
    positions: rug.components.map(x => x.positions),
    visibles: rug.components.map(x => true),
    textureId: rug.textureId,
    roomId: rug.roomId,
    viewMode: rug.roomId > 0 ? '3d' : '2d'
  };

  return rugFromQuery(query);
}

function rugFromQuery(query: RugQuery): string {
  const uri = new URI('/create/rug')
    .duplicateQueryParameters(true);

  uri.addQuery({
    'd': query.designs.join(','),
    's': query.sections.join(',')
  });

  if (query.scales.some(x => x !== 1)) {
    uri.addQuery({
      'sx': query.scales.join(',')
    });
  }

  if (query.visibles.some(x => !x)) {
    uri.addQuery({
      'v': query.visibles.map(x => x ? '1' : '0')
        .join(',')
    });
  }

  if (query.textureId) {
    uri.addQuery({
      't': query.textureId
    });
  }

  if (query.roomId) {
    uri.addQuery({
      'r': query.roomId
    });
  }

  if (query.viewMode) {
    uri.addQuery({
      'vm': query.viewMode
    });
  }

  // Adding the palette and positions last to ensure they get pushed to the end of the URL.
  uri.addQuery({
    'p': query.palette.join(','),
    'pos': query.positions.map(
      p => (p || []).join(',')
    ).join('|')
  });

  return uri.toString();
}

export function login() {
  return '/login';
}

export function account() {
  return '/account';
}

export function accountOrder(orderId: number) {
  return `/account/orders/${orderId}`;
}

export function accountTryk(orderId: number, trykId: number) {
  return `/account/orders/${orderId}/tryks/${trykId}`;
}

export function accountEdit() {
  return '/account/edit';
}

export function accountReset(id: string): string {
  return `/reset/${id}`;
}

export function projects() {
  return '/saved/projects';
}

export function viewProject(projectId: number) {
  return `/saved/projects/${projectId}`;
}

export function projectUsers(projectId: number) {
  return `/saved/projects/${projectId}/users`;
}

export function projectFolder(projectId: number, folderId: number) {
  return `/saved/projects/${projectId}?f=${folderId}`;
}

export function projectItem(projectId: number, folderItemId: number, folderId?: number) {
  const uri = new URI(viewProject(projectId))
    .segment('items')
    .segment(folderItemId.toString());

  /*uri.addQuery({
      'i': folderItemId
  });*/

  if (folderId > 0) {
    uri.addQuery({
      'f': folderId
    });
  }

  return uri.toString();
}
